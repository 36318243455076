import React from 'react';
import Loadable from 'react-loadable'
import {Route, Switch} from 'react-router-dom'
import { Router, hashHistory } from 'react-router';
// import Home from './containers/Home'
// import Bio from './containers/BioPage'
// import Social from './containers/Social'
// import Album from './containers/Album'
import Loader from './components/loading/loading'
// import Crew from './containers/CastPage'
// import AlbumDisplay from './containers/AlbumDisplay'
import firebase from 'firebase'

import './App.css';

const firebaseConfig = {
  apiKey: "AIzaSyCeXZgBUp9bjlZcCmZWuhFR8Y5QSmQGxMA",
  authDomain: "lucky-bodega.firebaseapp.com",
  databaseURL: "https://lucky-bodega.firebaseio.com",
  projectId: "lucky-bodega",
  storageBucket: "lucky-bodega.appspot.com",
  messagingSenderId: "455668563115",
  appId: "1:455668563115:web:4912c9af079e8c47ea0bee",
  measurementId: "G-0CC5FMSQXZ"
};
firebase.initializeApp(firebaseConfig);

const AsyncHome = Loadable({
  loader: () => import('./containers/Home'),
  loading: Loader,
})
const AsyncBio = Loadable({
  loader: () => import('./containers/BioPage'),
  loading: Loader,
})
const AsyncSocial = Loadable({
  loader: () => import('./containers/Social'),
  loading: Loader,
})
const AsyncAlbum= Loadable({
  loader: () => import('./containers/Album'),
  loading: Loader,
})

const AsyncAlbumDisplay = Loadable({
  loader: () => import('./containers/AlbumDisplay'),
  loading: Loader,
})
const AsyncCrew = Loadable({
  loader: () => import('./containers/CastPage'),
  loading: Loader,
})
const AsyncStore= Loadable({
  loader: () => import('./containers/Store'),
  loading: Loader,
})
function App() {
  return (
      <div>
        <Switch>
        {/* <Route path="/Contact" component={} />  */}
        <Route path="/" exact component={AsyncHome} />
        <Route path="/cast/" component={AsyncBio}></Route>
        <Route path="/crew/" component={AsyncCrew}></Route>
        <Route path="/Album/" component={AsyncAlbum}></Route>
        <Route path="/AlbumDisplay/:folder?" component={AsyncAlbumDisplay}></Route>
        <Route path="/Social/" component={AsyncSocial}></Route>
        <Route path="/Category" component={AsyncAlbumDisplay}></Route>
        <Route path="/Shop" component={AsyncStore}></Route>
        </Switch>
      </div>
  );
}

export default App;
