import React from 'react'
import './loading.css'
const Loader = () => {
    return (
        <div>
  <div class="sk-folding-cube">
  <div class="sk-cube1 sk-cube"></div>
  <div class="sk-cube2 sk-cube"></div>
  <div class="sk-cube4 sk-cube"></div>
  <div class="sk-cube3 sk-cube"></div>
    </div>
</div>
    )
}

export default Loader