import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter} from 'react-router-dom'
import { Router, hashHistory } from 'react-router';
import {createStore} from 'redux'
import {Provider} from 'react-redux'
const initalState = 15;
const myReducer = (state = initalState, action)=>{
    return state
}
const store = createStore(myReducer)

const app = (
    <Provider store={store}>
    <BrowserRouter history={Router.hashHistory}>
    <App/>
    </BrowserRouter>
    </Provider>
)
ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
